import React from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_EVENTS_CREATE } from 'Consts/routes';
import { OFFER_TYPE_DEFAULT, OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';

import { withVariables } from 'Utils/string';

import PageContent from 'Components/layout/panel/PageContent';
import AssignAdeptModal from 'Components/trainer/modals/AssignUser';
import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UsersList from 'Components/trainer/users/List';

export default class TrainerUsers extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    state = {
        showAssignAdeptModal:false,
    }

    render() {
        const { location, history } = this.props;
        const { showAssignAdeptModal } = this.state;

        return (
            <StyledComponent
                className='trainer-users'
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Adepci"
                        controls={[
                            {
                                key: 'create',
                                visible: true,
                                style: 'gradient',
                                label: 'Dodaj adepta',
                                onClick: () => this.setState({ showAssignAdeptModal:true }),
                            },
                        ]}
                        tabs={[{
                            key: 'default',
                            label: 'Abonamentowi',
                            children: (
                                <UsersList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        offerType: OFFER_TYPE_DEFAULT,
                                    }}
                                />
                            ),
                        }, {
                            key: 'diagnostic',
                            label: 'Diagnostyczni',
                            children: (
                                <UsersList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        offerType: OFFER_TYPE_DIAGNOSTIC,
                                    }}
                                />
                            ),
                        }, {
                            key: 'all',
                            label: 'Wszyscy',
                            children: (
                                <UsersList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        offerType: undefined,
                                    }}
                                />
                            ),
                        }]}
                    />
                </PageContent>
                <AssignAdeptModal
                    isOpen={showAssignAdeptModal}
                    onClose={() => {  
                        this.setState({ showAssignAdeptModal:false });
                    }}
                    onSuccess={this.loadData}
                    location={location}
                    history={history}
                />
            </StyledComponent>
        );
    }
}