import { connect } from 'react-redux';
import Component from './component';

import { 
    list, 
    assignToTrainer, 
    listNotAssignedToTrainer,
} from 'Redux/modules/trainer/users/actions';

export default connect(
    state => ({
        users: state.trainerUsers,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            assignToTrainer: dispatch(assignToTrainer),
            listNotAssignedToTrainer: dispatch(listNotAssignedToTrainer),
        },
    })
)(Component);
