import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import AssignUserEditor from 'Components/trainer/users/AssignUser';

export default class TrainerModalEventPlanner extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onSuccess: PropTypes.func,
    };

    static defaultProps = {
        isOpen: false,
        onClose: null,
    };

    state = {};

    render() {
        const { location, history, isOpen, onClose, onSuccess } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="trainer-modal-assign-user" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Dodaj Adepta" />
                        <AssignUserEditor 
                            location={location}
                            history={history}
                            onSuccess={onSuccess}
                            onClose={onClose}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
